<template>
  <b-container>
    <b-row class="my-3" align-h="center">
      <b-col cols="12" sm="9">
        <b-card>
          <b-form @submit.prevent="UpdateUserInform()">
            <b-row>
              <b-col cols="12" sm="6">
                <b-form-group
                  id="input-group-firstname"
                  :label="$t('FirstName')"
                  label-for="input-firstname"
                  label-class="text-primary px-2"
                  label-align="right"
                >
                  <b-form-input
                    id="input-firstname"
                    v-model="form.FirstNameI"
                    :state="validateState('FirstNameI')"
                    :placeholder="$t('FirstName')"
                    class="rounded-form"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="12" sm="6">
                <b-form-group
                  id="input-group-lastname"
                  :label="$t('LastName')"
                  label-for="input-lastname"
                  label-class="text-primary px-2"
                  label-align="right"
                >
                  <b-form-input
                    id="input-lastname"
                    v-model="form.LastNameI"
                    :state="validateState('LastNameI')"
                    :placeholder="$t('LastName')"
                    class="rounded-form"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" sm="12">
                <b-form-group
                  id="input-group-display-name"
                  :label="$t('DisplayName')"
                  label-for="input-display-name"
                  label-class="text-primary px-2"
                  label-align="right"
                >
                  <b-form-input
                    id="input-display-name"
                    v-model="form.DisplayNameI"
                    :state="validateState('DisplayNameI')"
                    :placeholder="$t('DisplayName')"
                    class="rounded-form"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" sm="12">
                <b-form-group
                  id="input-group-mail"
                  :label="$t('email')"
                  label-for="input-mail"
                  label-class="text-primary px-2"
                  label-align="right"
                >
                  <b-form-input
                    id="input-mail"
                    v-model="form.MailI"
                    :state="validateState('MailI')"
                    type="email"
                    :placeholder="$t('EnterMail')"
                    class="rounded-form"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row align-content="center" align-h="center">
              <b-col cols="auto" class="ml-auto">
                <b-button variant="outline-secondary" @click="showConBox">
                  {{ $t("SignOut") }}
                </b-button>
              </b-col>
              <b-col cols="auto" class="mx-2">
                <b-button
                  type="submit"
                  variant="outline-primary"
                  class="px-5"
                  >{{ $t("Save") }}</b-button
                >
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
// import UserLinks from '../components/widgets/UserLinks.vue';
import { mapActions, mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import i18n from "../main.js";
export default {
  components: {},
  data() {
    return {
      show: false,
      form: {
        FirstNameI: "",
        LastNameI: "",
        DisplayNameI: "",
        MailI: "",
        id: "",
      },
    };
  },
  validations: {
    form: {
      FirstNameI: {
        required,
      },
      LastNameI: {
        required,
      },
      DisplayNameI: {
        required,
      },
      MailI: {
        required,
      },
    },
  },
  methods: {
    ...mapActions(["UpdateUser"]),
    showConBox() {
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm(i18n.t("You will be logged out, are you sure?"), {
          title: i18n.t("Please Confirm"),
          size: "sm",
          buttonSize: "sm",

          bodyClass: "text-center my-2",
          okVariant: "primary",
          okTitle: i18n.t("YES"),
          cancelTitle: i18n.t("NO"),
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.logout();
          }
          this.boxTwo = value;
        });
    },
    logout() {
      this.show = true;
      setTimeout(() => {
        this.show = false;
        this.$router.push({ name: "LogOut" });
      }, 1500);
    },
    UpdateUserInform() {
      this.UpdateUser(this.form);
      this.$bvModal
        .msgBoxOk(i18n.t("DataSubmitted"), {
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: i18n.t("ok"),
          headerClass: "p-2 border-bottom-0 font-weight-bold",
          footerClass: "p-2 border-top-0 mx-auto ",
          modalClass: " p-1 pt-3 text-center ",
          bodyClass: " pt-2 ",
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.$router.push({ name: "Home" });
          }
        });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
  },
  computed: {
    ...mapGetters(["User"]),
  },
     metaInfo: {
    title: "Profile",
  },
  mounted() {
    this.$gtag.event("User Profile Page", { method: "Google" });

    this.form.FirstNameI = this.User.first_name;
    this.form.LastNameI = this.User.last_name;
    this.form.DisplayNameI = this.User.display_name;
    this.form.MailI = this.User.email;
    this.form.id = this.User.ID;
  },
};
</script>

<style>
</style>